import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { FaHome, FaTasks, FaEnvelope, FaNewspaper, FaBars, FaCalendarAlt, FaSignOutAlt, FaTimes, FaCamera } from 'react-icons/fa';
import './Navigation.css';
import { logoutUser } from '../../actions/LaunchActions';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { SiBoat } from 'react-icons/si';

const Navigation = ({ children }) => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [isTabletOrMobile, setIsTabletOrMobile] = useState(false);
    const [isMenuOverlayVisible, setIsMenuOverlayVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const toggleSidebar = () => setIsOpen(!isOpen);

    const menuItem = [
        { path: "/", name: "Home", icon: <FaHome /> },
        { path: "/bookings", name: "Bookings", icon: <FaCalendarAlt /> },
        { path: "/catalogue", name: "Catalogue", icon: <SiBoat /> },
        { path: "/events", name: "Events", icon: <FaTasks /> },
        { path: "/pictures", name: "Gallery", icon: <FaCamera /> },
        { path: "/messages", name: "Messages", icon: <FaEnvelope /> },
        { path: "/newsletters", name: "Newsletters", icon: <FaNewspaper /> },
    ];

    const handleMenuItemClick = () => {
        if (window.innerWidth <= 768) {
            setIsMenuOverlayVisible(false);
        }
    };

    const handleLogoutClick = () => {
        if (window.innerWidth <= 768) {
            setIsMenuOverlayVisible(false);
            setIsModalVisible(true);
        }
    };

    const confirmLogout = () => {
        dispatch(logoutUser());
    };

    useEffect(() => {
        const handleResize = () => {
            setIsTabletOrMobile(window.innerWidth <= 768);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="navigation-container">
            {/* Sidebar for larger screens */}
            {!isTabletOrMobile && (
                <div className={`sidebar ${isOpen ? 'open' : ''}`}>
                    <div className="toggle-btn" onClick={toggleSidebar}>
                        {isOpen ?
                            <FaTimes />
                            :
                            <FaBars />
                        }
                    </div>
                    <div className={`menu ${isOpen ? 'open' : ''}`}>
                        {menuItem.map((item, index) => (
                            <NavLink
                                to={item.path}
                                key={index}
                                className="link"
                                activeclassname="active"
                                onClick={handleMenuItemClick}
                            >
                                <div className="icon">{item.icon}</div>
                                {isOpen && <div className="link_text">{item.name}</div>}
                            </NavLink>
                        ))}
                        <div className="link_logout" onClick={() => setIsModalVisible(true)}>
                            <div className="icon_logout">
                                <FaSignOutAlt />
                            </div>
                            {isOpen && <div className="link_text_logout">Logout</div>}
                        </div>
                    </div>
                </div>
            )}

            {/* Bottom tab navigation for smaller screens */}
            {isTabletOrMobile && (
                <>
                    <div className="bottom-nav">
                        <div className="nav-item">Yacht</div>
                        <div className="nav-item toggle-center" onClick={() => setIsMenuOverlayVisible(true)}>
                            <FaBars />
                        </div>
                        <div className="nav-item">Admin</div>
                    </div>

                    {/* Full-screen overlay menu for small screens */}
                    {isMenuOverlayVisible && (
                        <div className="menu-overlay">
                            <div className="close-btn" onClick={() => setIsMenuOverlayVisible(false)}>
                                &times;
                            </div>
                            <div className="menu-overlay-content">
                                {menuItem.map((item, index) => (
                                    <NavLink
                                        to={item.path}
                                        key={index}
                                        className="menu-overlay-link"
                                        onClick={handleMenuItemClick}
                                    >
                                        {item.icon} {item.name}
                                    </NavLink>
                                ))}
                                <div className='menu-overlay-link logout_link' onClick={handleLogoutClick}>
                                    <FaSignOutAlt /> Logout
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}

            <main className={`content ${!isOpen && !isTabletOrMobile ? 'shifted' : ''}`}>
                {children}
            </main>

            <Modal
                title="Confirm Logout"
                open={isModalVisible}
                onOk={confirmLogout}
                onCancel={() => setIsModalVisible(false)}
                okText="Yes"
                cancelText="No"
            >
                <p>Are you sure you want to logout?</p>
            </Modal>
        </div>
    );
};

export default Navigation;
