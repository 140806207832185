import { FETCH_PICTURES_SUCCESS } from '../actions/Types';

const initialState = {
    pictures: [],
    loading: false,
    error: null,
};

const PicturesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PICTURES_SUCCESS:
            return {
                ...state,
                pictures: action.payload,
            };
       
        default:
            return state;
    }
};

export default PicturesReducer;
