import {combineReducers} from "redux";

//import reducers
import MessagesReducer from "./MessagesReducer";
import ActivitiesReducer from "./ActivitiesReducer";
import NewsletterReducer from "./NewslettersReducer";
import BoatsReducer from "./BoatsReducer";
import EventsReducer from "./EventsReducer";
import PicturesReducer from "./PicturesReducer";
import BookingsReducer from "./BookingsReducer";
import LaunchReducer from "./LaunchReducer";
// import AnalyticsReducer from "./AnalyticsReducer";
// import contactReducer from "./ContactReducer";

export default combineReducers({
    activities: ActivitiesReducer,
    launch: LaunchReducer,
    messages: MessagesReducer,
    newsletters: NewsletterReducer,
    boats: BoatsReducer,
    events: EventsReducer,
    pictures: PicturesReducer,
    bookings: BookingsReducer,
    
});