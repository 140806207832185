// bookingsReducer.js
const initialState = {
    bookings: [],
    loading: false,
    error: null,
  };
  
  const BookingsReducer = (state = initialState, action) => {
    switch (action.type) {
      case "FETCH_BOOKINGS_REQUEST":
        return { ...state, loading: true };
      case "FETCH_BOOKINGS_SUCCESS":
        return { ...state, bookings: action.payload, loading: false };
      case "FETCH_BOOKINGS_FAILURE":
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export default BookingsReducer;
  