
//............CONTACT ACTIONS
export const ADD_EMAIL = "add_email";
export const ADD_EMAIL_SUCCESS = "add_email_success";
export const ADD_EMAIL_FAILURE = "add_email_failure";

export const CREATE_MESSAGE = "create_message";
export const CREATE_MESSAGE_SUCCESS = "create_message_success";
export const CREATE_MESSAGE_FAILURE = "create_message_failure";

export const FETCH_ALL_NEWSLETTERS_REQUEST = 'FETCH_ALL_NEWSLETTERS_REQUEST';
export const FETCH_ALL_NEWSLETTERS_SUCCESS = 'FETCH_ALL_NEWSLETTERS_SUCCESS';
export const FETCH_ALL_NEWSLETTERS_FAILURE = 'FETCH_ALL_NEWSLETTERS_FAILURE';

export const DELETE_NEWSLETTER_REQUEST = 'DELETE_NEWSLETTER_REQUEST';
export const DELETE_NEWSLETTER_SUCCESS = 'DELETE_NEWSLETTER_SUCCESS';
export const DELETE_NEWSLETTER_FAILURE = 'DELETE_NEWSLETTER_FAILURE';

export const FETCH_ALL_MESSAGES_REQUEST = 'FETCH_ALL_MESSAGES_REQUEST';
export const FETCH_ALL_MESSAGES_SUCCESS = 'FETCH_ALL_MESSAGES_SUCCESS';
export const FETCH_ALL_MESSAGES_FAILURE = 'FETCH_ALL_MESSAGES_FAILURE';

export const DELETE_MESSAGE_REQUEST = 'DELETE_MESSAGE_REQUEST';
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS';
export const DELETE_MESSAGE_FAILURE = 'DELETE_MESSAGE_FAILURE';




//.........AUTH ACTIONS
export const AUTH_FIELD_CHANGED = "auth_field_changed";
export const LOGIN_USER = "login_user";
export const LOGIN_USER_SUCCESSFUL = "login_user_successful";
export const LOGIN_USER_FAILED = "login_user_failed";
export const LOGOUT_USER = "logout_user";
export const LOGOUT_USER_SUCCESSFUL = "logout_user_successful";
export const LOGOUT_USER_FAILED = "logout_user_successful";
export const REGISTER_USER = "register_user";
export const REGISTER_USER_SUCCESSFUL = "register_user_successful";
export const REGISTER_USER_FAILED = "register_user_failed";

//.......MESSAGE ACTIONS
export const SET_MESSAGE = "set_message";
export const CLEAR_MESSAGE = "clear_message";

//.......LAUNCH ACTIONS
export const LOGOUT = 'logout';

//.......ACTIVITY ACTIONS
export const DELETE_ACTIVITY_SUCCESS = 'delete_activity_success';
export const DELETE_ACTIVITY_FAILURE = 'delete_activity_failure';



//.......PICTURE ACTIONS
export const FETCH_PICTURES_REQUEST = 'fetch_pictures_request';
export const FETCH_PICTURES_SUCCESS = 'fetch_pictures_success';
export const FETCH_PICTURES_FAILURE = 'fetch_pictures_failure';

export const ADD_PICTURE_COLLECTION_REQUEST = 'add_picture_collection_request';
export const ADD_PICTURE_COLLECTION_SUCCESS = 'add_picture_collection_success';
export const ADD_PICTURE_COLLECTION_FAILURE = 'add_picture_collection_failure';

export const EDIT_PICTURE_COLLECTION_REQUEST = 'edit_picture_collection_request';
export const EDIT_PICTURE_COLLECTION_SUCCESS = 'edit_picture_collection_success';
export const EDIT_PICTURE_COLLECTION_FAILURE = 'edit_picture_collection_failure';

export const DELETE_PICTURE_COLLECTION_REQUEST = 'delete_picture_collection_request';
export const DELETE_PICTURE_COLLECTION_SUCCESS = 'delete_picture_collection_success';
export const DELETE_PICTURE_COLLECTION_FAILURE = 'delete_picture_collection_failure';

export const ADD_IMAGE_TO_COLLECTION_REQUEST = 'add_image_to_collection_request';
export const ADD_IMAGE_TO_COLLECTION_SUCCESS = 'add_image_to_collection_success';
export const ADD_IMAGE_TO_COLLECTION_FAILURE = 'add_image_to_collection_failure';

export const DELETE_IMAGE_FROM_COLLECTION_REQUEST = 'delete_image_from_collection_request';
export const DELETE_IMAGE_FROM_COLLECTION_SUCCESS = 'delete_image_from_collection_success';
export const DELETE_IMAGE_FROM_COLLECTION_FAILURE = 'delete_image_from_collection_failure';
