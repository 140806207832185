import { FETCH_EVENTS_SUCCESS } from '../actions/EventsActions';
import {
   
} from '../actions/Types';

const initialState = {
    events: [],
    loading: false,
    error: null,
};

const EventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EVENTS_SUCCESS:
            return {
                ...state,
                events: action.payload,
            };
       
        default:
            return state;
    }
};

export default EventsReducer;
