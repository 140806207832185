import { FETCH_BOATS_SUCCESS } from '../actions/BoatActions';
import {
   
} from '../actions/Types';

const initialState = {
    boats: [],
    loading: false,
    error: null,
};

const BoatsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BOATS_SUCCESS:
            return {
                ...state,
                boats: action.payload,
            };
       
        default:
            return state;
    }
};

export default BoatsReducer;
