import React, { useState, useEffect, lazy, Suspense } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';


import logo from './assets/logo512.png';

//file imports
// import Activities from './components/admin/Activities';
// import Contacts from './components/superAdmin/Contacts';
// import Activity from './components/admin/Activity';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
// import Team from './components/team/Team';
// import Employee from './components/team/Employee';
// import Footer from './components/footer/Footer';
// import NotFound from './components/notFound/NotFound';
// import Offers from './components/offers/Offers';
// import SideBar from './components/sideBars/SideBar';
// import Home from './components/home/Home';
import Footer from './components/footer/Footer';
// import Activities from './components/activities/Activities';
// import Messages from './components/messages/Messages';
// import Newsletters from './components/newsletters/Newsletters';
// import Login from './components/launch/Login';
import Navigation from './components/navigation/Navigation';
// import Events from './components/events/Events';
// import Bookings from './components/bookings/Bookings';
// import Catalogue from './components/catalogue/Catalogue';
// import Pictures from './components/pictures/Pictures';

const Home = lazy(() => import('./components/home/Home'));
// const Footer = lazy(() => import('./components/footer/Footer'));
const Messages = lazy(() => import('./components/messages/Messages'));
const Newsletters = lazy(() => import('./components/newsletters/Newsletters'));
const Login = lazy(() => import('./components/launch/Login'));
// const Navigation = lazy(() => import('./components/navigation/Navigation'));
const Events = lazy(() => import('./components/events/Events'));
const Bookings = lazy(() => import('./components/bookings/Bookings'));
const Catalogue = lazy(() => import('./components/catalogue/Catalogue'));
const Pictures = lazy(() => import('./components/pictures/Pictures'));

const firebaseConfig = {
  apiKey: "AIzaSyAKNRssHROgM1HYHGLCVD71uj6_u-rbp5U",
  authDomain: "yatch-cruise-connect.firebaseapp.com",
  projectId: "yatch-cruise-connect",
  storageBucket: "yatch-cruise-connect.appspot.com",
  messagingSenderId: "651106916618",
  appId: "1:651106916618:web:a13ef58bdc0ceeb0f882f3",
  measurementId: "G-2ZZCQ5TFZ4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

const App = () => {

  const [user, setUser] = useState(null);
  const [checkedAuth, setCheckedAuth] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setCheckedAuth(true);
    });

    return () => unsubscribe();
  }, []);


  const renderLogo = () => {
    return (
      // <SideBar>
      <div className='center-placeholder'>
        <img src={logo} alt='logo' />
        {/* <Footer /> */}
      </div>
      // </SideBar>
    );
  };
  const renderLogin = () => {
    return (
      // <SideBar>
      <div className="mainContainer">
        <Suspense fallback={
          <div className='center-placeholder'>
            <img src={logo} alt='logo' />
          </div>
        }>
          <Routes>
            <Route path="*" element={<Login />} />
          </Routes>
          {/* <Footer /> */}
        </Suspense>

      </div>
      // </SideBar>
    );
  };

  const renderAccess = () => {
    // if (user) {
    //   if (!_.isEmpty(myProfile) && myProfile.role === "Super Admin") {
    //     return (
    //       <SuperAdminSideBars>
    //         <div className="mainContainer">
    //           <Routes>
    //             {/* <Route path='/' element={<Home />} />
    //             <Route path='/contacts' element={<Contacts />} />
    //             <Route path='/bookings' element={<Booking />} /> */}
    //           </Routes>
    //         </div>
    //       </SuperAdminSideBars>
    //     );
    //   } else {
    //     return (
    //       <AdminTopbars>
    //         <div className="mainContainer">
    //           <Routes>
    //             <Route path='/' element={<Home />} />
    //             <Route path='/activities' element={<Activities />} />
    //             <Route path='/messages' element={<Messages />} />
    //             <Route path='/newsletter' element={<Newsletter />} />
    //             <Route path="/activity/:id" element={<Activity />} />
    //           </Routes>
    //         </div>
    //       </AdminTopbars>
    //     );
    //   }
    // } else {

    // if (!user) {
    //   <Router>
    //     <Switch>
    //       <Route exact path="/login">
    //         {user ? <Redirect to="/" /> : <Login />}
    //       </Route>
    //       <Route path="*">
    //         <NotFound />
    //       </Route>
    //     </Switch>
    //   </Router>
    // } else {

    return (
      <Navigation>
        <div className="mainContainer">
          <Suspense fallback={
            <div className='center-placeholder'>
              <img src={logo} alt='logo' />
            </div>
          }>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/catalogue' element={<Catalogue />} />
              <Route path='/bookings' element={<Bookings />} />
              <Route path='/events' element={<Events />} />
              <Route path='/pictures' element={<Pictures />} />
              <Route path='/messages' element={<Messages />} />
              <Route path='/newsletters' element={<Newsletters />} />
            </Routes>
          </Suspense>
        </div>
        <Footer />
      </Navigation>
    );
  };

  if (
    !checkedAuth
  ) {
    renderLogo()
  }


  return (
    <BrowserRouter>
      {
        user ?
          renderAccess()
          :
          renderLogin()
      }
    </BrowserRouter>
  );


};

export default App;
