import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  arrayRemove,
} from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';

import { db, storage } from '../App';
import { message } from 'antd';

// Action Types
export const FETCH_BOATS = 'FETCH_BOATS_REQUEST';
export const FETCH_BOATS_SUCCESS = 'FETCH_BOATS_SUCCESS';
export const FETCH_BOATS_FAILURE = 'FETCH_BOATS_FAILURE';

export const ADD_BOAT_REQUEST = 'ADD_BOAT_REQUEST';
export const ADD_BOAT_SUCCESS = 'ADD_BOAT_SUCCESS';
export const ADD_BOAT_FAILURE = 'ADD_BOAT_FAILURE';

export const EDIT_BOAT_REQUEST = 'EDIT_BOAT_REQUEST';
export const EDIT_BOAT_SUCCESS = 'EDIT_BOAT_SUCCESS';
export const EDIT_BOAT_FAILURE = 'EDIT_BOAT_FAILURE';

export const DELETE_BOAT_REQUEST = 'DELETE_BOAT_REQUEST';
export const DELETE_BOAT_SUCCESS = 'DELETE_BOAT_SUCCESS';
export const DELETE_BOAT_FAILURE = 'DELETE_BOAT_FAILURE';



// Upload image to Firebase Storage
export const uploadImage = (file, onProgress) => {
  return new Promise((resolve, reject) => {
    const storageRef = ref(storage, `boats/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Calculate and report progress
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (onProgress) {
          onProgress(progress);
        }
      },
      (error) => {
        reject(error);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        resolve(downloadURL);
      }
    );
  });
};

// Delete image from Firebase Storage
export const deleteImage = (boatID, imageUrl) => {
  return async dispatch => {

    const storageRef = ref(storage, imageUrl);
    try {
      await deleteObject(storageRef);

      if (boatID) {
        const boatDoc = doc(db, 'boats', boatID);
        // Remove image URL from the Firestore document's images array
        await updateDoc(boatDoc, {
          images: arrayRemove(imageUrl),
        });
        dispatch(fetchAllBoats()); // Re-fetch after deleting image
      }

    } catch (error) {
      console.error("Error deleting image from storage", error);
    }
  };
};


// Fetch all boats
export const fetchAllBoats = () => {
  return async dispatch => {
    dispatch({ type: FETCH_BOATS });

    try {
      const boatsCollection = collection(db, 'boats');
      const boatsSnapshot = await getDocs(boatsCollection);
      const boats = boatsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      dispatch({ type: FETCH_BOATS_SUCCESS, payload: boats });
    } catch (error) {
      dispatch({ type: FETCH_BOATS_FAILURE, payload: error.message });
    }
  };
};

// Add a new boat
export const addBoat = (boatData) => {
  return async dispatch => {
    dispatch({ type: ADD_BOAT_REQUEST });

    try {
      const boatsCollection = collection(db, 'boats');
      await addDoc(boatsCollection, boatData);

      dispatch({ type: ADD_BOAT_SUCCESS });
      message.success('Boat added successfully!');

      dispatch(fetchAllBoats()); // Refresh boats list after adding
    } catch (error) {
      dispatch({ type: ADD_BOAT_FAILURE, payload: error.message });
      message.error('Failed to save boat!');

    }
  };
};

// Edit an existing boat
export const editBoat = (boatID, boatData) => {
  return async dispatch => {
    dispatch({ type: EDIT_BOAT_REQUEST });

    try {
      const boatRef = doc(db, 'boats', boatID);
      await updateDoc(boatRef, boatData);

      dispatch({ type: EDIT_BOAT_SUCCESS });
      dispatch(fetchAllBoats()); // Refresh boats list after editing

      message.success('Boat updated successfully!');

    } catch (error) {
      dispatch({ type: EDIT_BOAT_FAILURE, payload: error.message });
      message.error('Failed to save boat!');

    }
  };
};

// Delete a boat by ID
export const deleteBoat = (boatID) => {
  return async dispatch => {
    dispatch({ type: DELETE_BOAT_REQUEST });

    try {
      const boatRef = doc(db, 'boats', boatID);
      await deleteDoc(boatRef);

      dispatch({ type: DELETE_BOAT_SUCCESS });
      dispatch(fetchAllBoats()); // Refresh boats list after deleting
      message.success('Boat deleted successfully!');

    } catch (error) {
      dispatch({
        type: DELETE_BOAT_FAILURE,
        payload: error.message,
      });
      message.error('Failed to delete boat!');

    }
  };
};
