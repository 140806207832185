import { LOGIN_USER, LOGIN_USER_FAILED, LOGIN_USER_SUCCESSFUL } from '../actions/Types';

const initialState = {
    loading: false,
    error: null,
};

const LaunchReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return {
                ...state,
                loading: true,
                error: null
            };
        case LOGIN_USER_SUCCESSFUL:
            return {
                ...state,
                loading: false
            };
        case LOGIN_USER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        default:
            return state;
    }
};

export default LaunchReducer;
