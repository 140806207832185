import {
  collection,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  deleteDoc
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { db, storage } from '../App';
import { message } from 'antd';

// Action Types
export const FETCH_EVENTS_REQUEST = 'FETCH_EVENTS_REQUEST';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAILURE = 'FETCH_EVENTS_FAILURE';

export const ADD_EVENT_REQUEST = 'ADD_EVENT_REQUEST';
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS';
export const ADD_EVENT_FAILURE = 'ADD_EVENT_FAILURE';

export const EDIT_EVENT_REQUEST = 'EDIT_EVENT_REQUEST';
export const EDIT_EVENT_SUCCESS = 'EDIT_EVENT_SUCCESS';
export const EDIT_EVENT_FAILURE = 'EDIT_EVENT_FAILURE';

export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE';

export const UPLOAD_IMAGE_REQUEST = 'UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'UPLOAD_IMAGE_FAILURE';

export const DELETE_IMAGE_REQUEST = 'DELETE_IMAGE_REQUEST';
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS';
export const DELETE_IMAGE_FAILURE = 'DELETE_IMAGE_FAILURE';

// Fetch all events
export const fetchAllEvents = () => async (dispatch) => {
  dispatch({ type: FETCH_EVENTS_REQUEST });

  try {
    const eventsRef = collection(db, 'events');
    const querySnapshot = await getDocs(eventsRef);

    const events = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    dispatch({ type: FETCH_EVENTS_SUCCESS, payload: events });
  } catch (error) {
    dispatch({
      type: FETCH_EVENTS_FAILURE,
      payload: error.message,
    });
  }
};

// Add a new event
export const addEvent = (eventData) => async (dispatch) => {
  dispatch({ type: ADD_EVENT_REQUEST });
  console.log(eventData)

  try {
    const eventsRef = collection(db, 'events');
    await addDoc(eventsRef, eventData);

    dispatch({ type: ADD_EVENT_SUCCESS });
    message.success('Event added successfully!');

    dispatch(fetchAllEvents()); // Refresh events list after adding
  } catch (error) {
    message.error('Failed to save event!');
console.log(error)
    dispatch({
      type: ADD_EVENT_FAILURE,
      payload: error.message,
    });
  }
};

// Edit an event
export const editEvent = (eventID, updatedData) => async (dispatch) => {
  dispatch({ type: EDIT_EVENT_REQUEST });

  try {
    const eventRef = doc(db, 'events', eventID);
    await updateDoc(eventRef, updatedData);

    dispatch({ type: EDIT_EVENT_SUCCESS });
    message.success('Event updated successfully!');

    dispatch(fetchAllEvents()); // Refresh events list after editing
  } catch (error) {
    message.error('Failed to save event!');

    dispatch({
      type: EDIT_EVENT_FAILURE,
      payload: error.message,
    });
  }
};

// Delete an event by ID
export const deleteEvent = (eventID) => async (dispatch) => {
  dispatch({ type: DELETE_EVENT_REQUEST });

  try {
    const eventRef = doc(db, 'events', eventID);
    await deleteDoc(eventRef);

    dispatch({ type: DELETE_EVENT_SUCCESS });
    message.success('Event deleted successfully!');

    dispatch(fetchAllEvents()); // Refresh events list after deleting

  } catch (error) {
    message.error('Failed to delete event!');

    dispatch({
      type: DELETE_EVENT_FAILURE,
      payload: error.message,
    });
  }
};

// Upload an image
export const uploadImage = (file) => async (dispatch) => {
  dispatch({ type: UPLOAD_IMAGE_REQUEST });

  try {
    const storageRef = ref(storage, `events/${file.name}`);
    const snapshot = await uploadBytes(storageRef, file);
    const url = await getDownloadURL(snapshot.ref);

    dispatch({ type: UPLOAD_IMAGE_SUCCESS, payload: url });
    return url; // Return the URL after successful upload
  } catch (error) {
    dispatch({
      type: UPLOAD_IMAGE_FAILURE,
      payload: error.message,
    });
    return null;
  }
};

// Delete an image
export const deleteImage = (imageURL) => async (dispatch) => {
  dispatch({ type: DELETE_IMAGE_REQUEST });

  try {
    const imageRef = ref(storage, imageURL);
    await deleteObject(imageRef);

    dispatch({ type: DELETE_IMAGE_SUCCESS });
  } catch (error) {
    dispatch({
      type: DELETE_IMAGE_FAILURE,
      payload: error.message,
    });
  }
};
